import React from "react";
import styled from "@emotion/styled";

import { Icon } from '@types';


const FigmatipLogo: Icon = ({ fill }) => {
  return (
    <LogoContainer>
      <svg
        viewBox="0 0 294 294"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M150.915 70.454c6.234-7.535 13.284-12.031 22.69-14.473 5.838-1.514 18.432-.94 23.972 1.094 2.393.878 5.016 2.211 5.829 2.962 1.476 1.363 1.472 1.366-2.715 1.824-9.342 1.023-15.439 5.76-19.333 15.021-1.206 2.87-2.193 5.64-2.193 6.157 0 1.073-6.796 26.855-11.729 44.501-1.823 6.519-3.314 11.966-3.314 12.106 0 .139 10.982.341 24.405.449l24.405.195-5.959 17.519h-48.015l-3.006 10.996c-10.004 36.603-13.052 45.409-18.054 52.164-5.716 7.721-14.913 14.222-24.139 17.064-5.985 1.844-16.359 1.7-23.348-.323-5.07-1.468-10.202-3.899-10.883-5.154-.17-.313 2.092-.57 5.024-.57 6.484-.001 11.217-2.193 15.653-7.25 3.755-4.279 5.637-8.879 9.539-23.315 1.774-6.56 5.021-18.435 7.218-26.388 2.195-7.953 3.992-15.081 3.992-15.842 0-1.321-1.064-1.382-23.943-1.382-18.897 0-23.873-.196-23.608-.932.184-.512 1.57-4.538 3.08-8.946l2.747-8.014h47.509l.674-2.423c.371-1.333 2.878-10.833 5.57-21.111 7.496-28.617 10.971-37.516 17.932-45.93Z" fill="#27292D"/>
        <path d="M230.125 88.339c0 12.699-10.295 22.994-22.994 22.994-12.7 0-22.995-10.295-22.995-22.994 0-12.7 10.295-22.994 22.995-22.994 12.699 0 22.994 10.294 22.994 22.994ZM100.676 204.161c0 12.699-10.295 22.994-22.994 22.994-12.7 0-22.994-10.295-22.994-22.994s10.295-22.994 22.994-22.994c12.7 0 22.994 10.295 22.994 22.994Z" fill="#27292D"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M147 294c81.186 0 147-65.814 147-147S228.186 0 147 0 0 65.814 0 147s65.814 147 147 147Zm0-11.594c74.782 0 135.406-60.624 135.406-135.406 0-74.782-60.624-135.406-135.406-135.406C72.218 11.594 11.594 72.217 11.594 147c0 74.782 60.623 135.406 135.406 135.406Z" fill="#27292D"/>
      </svg>
    </LogoContainer>
  );
};

export default FigmatipLogo;

const LogoContainer = styled.div`
  svg path {
    fill: ${p => p.theme.colors.primary};
    transition: fill 0.25s var(--ease-in-out-quad);
  }
`;